import './ReportRunConfigEditor.scss';
import React, {useEffect, useState} from "react";
import {RelativeTimePeriod, ReportRunConfig, RunConfigMarket} from "@/types/ReportRun";
import {ByzzerButton, ByzzerTipIcon} from "@byzzer/ui-components";
import DashboardContent from "@/components/dashboard/DashboardContent";
import {showErrorModal} from "@/utils";
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";
import {useTenantApi} from "@/hooks/useTenantApi";
import {alert} from "@/components/form/ByzzerModal";
import {getRunConfigOptionsBySku, getProductBySku, } from "@/services/product.service";
import {ProductRunConfigOptions, RunConfigOptions} from "@/types/RunConfigOptions";
import {useUser} from "@/contexts/UserContext";
import {ReportRunConfigWizard} from "@/components/ConfigurationEditors/ReportConfigurationEditor/ReportRunConfigWizard";
import {useReportRunService} from "@/services/reportRun.service";
import { SubscriptionInfo } from "@/constants/subscription.constants"
import { categoryAppearsInGivenList, removeCharsWithUnsubcribedCats } from '../AlertRunConfigEditor';
import { CreditUsage } from '@/types/ApiTypes';

export type ReportRunConfigEditorProps = React.HTMLAttributes<HTMLDivElement> & {}

// I don't think I will need this long term
export type ReportConfigurationValue = {}

type ReportType = 'core' | 'smart';

const baseClassName = 'report-run-config-editor';

export function ReportRunConfigEditor({className, ...props}: ReportRunConfigEditorProps) {
    const { createAdHicReportRun, createSubscriptionReportRun, getMySubscriptionUsage, getReportRunById, getReportRunConfigById, getRunConfigOptionsByCreditId } = useTenantApi();

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { getDefaultRunConfigBySku, getReportRunConfig } = useReportRunService();
    const [report, setReport] = useState<any>();
    const {subscribedSkus, categories: subscriptionCategories, omniCategories: subscriptionOmniCategories, customCharacteristics: subscriptionCustomCharacteristics} = useUser();
    const [runConfigOptions, setRunConfigOptions] = useState<RunConfigOptions[]>()
    const [defaultRunConfig, setDefaultRunConfig] = useState<Partial<ReportRunConfig>>({});
    const [reportType, setReportType] = useState<ReportType>('core');
    const [generating, setGenerating] = useState<boolean>(false);
    const [basicReportsUsage, setBasicReportsUsage] = useState<CreditUsage>({ limit: 0, used: 0 });
    const [defaultReport, setDefaultReport ]= useState<Partial<ReportRunConfig>>({});
    const runType: RunType = searchParams.get('runType') as RunType ?? 'subscription';
    const sku = searchParams.get('sku');
    const reportRunId = searchParams.get('reportId');
    const runCreditId = searchParams.get('creditId');
    const location = useLocation() as { state: { additionalPayload?: {
        "brands": string[],
        "markets": RunConfigMarket[],
        "timePeriod": RelativeTimePeriod,
        "category"?:[]
    } } | null };
    const additionalPayload = location?.state?.additionalPayload;

    useEffect(() => {
        ;(async () => {
            if (await validateOrExit()) {
                await loadConfig();
            }
            if(sku!=='456' && additionalPayload){
                if(additionalPayload?.markets[0]?.remainingMarketRunConfig)
                delete additionalPayload.markets[0]?.remainingMarketRunConfig
            }
        })();
    }, [sku]);

    async function loadConfig() {
        try {

            const report = getProductBySku(sku!);
            if(defaultRunConfig?.datatype){
                        if (additionalPayload) {
                         let newDefaultConfig={}as ReportRunConfig;
                         newDefaultConfig.focusBrands = additionalPayload?.brands
                         newDefaultConfig.timePeriod = additionalPayload?.timePeriod
                         newDefaultConfig.categories=additionalPayload?.category
                         newDefaultConfig.markets =additionalPayload?.markets
                         setDefaultReport({...defaultRunConfig,...newDefaultConfig})
                        }

                     }
            // todo: show message if there is no matching config for he sku
            if (!report) {
                await alert({
                    // @ts-ignore
                    title: 'Product Not Found',
                    content: `We were unable to find the product you are trying to configure.`,
                });

                return navigate(-1);
            }

            // todo: add support normalizing reports so this can just be check of single type instead of all this
            setReportType(report.metadata.reportOptions?.reportType.startsWith('smart') ? 'smart' : 'core');
            setReport(report);

            // if (creditId) {
            //     // todo: handle possible errors thrown like 401s
            //     let {max, completed} = await getCreditUsage(creditId);
            //     if (!max) {
            //         await alert({
            //             title: 'Invalid Credit',
            //             content: 'You have not purchased this report through a subscription or ad hoc report run.',
            //         });
            //         navigate(-1);
            //     } else if (max - completed < 1) {
            //         await alert({
            //             title: 'No Runs Remaining',
            //             content: 'You have used all purchased credits for the selected report.',
            //         });
            //         navigate(-1);
            //     }
            // }

            if (reportRunId) {
                try {
                    let {
                        sku: reportRunSku, 
                        configuration: { categories: selectedCategories, ...configuration },
                        ...reportRunConfig
                    } = await getReportRunConfigById(Number(reportRunId));

                    const { metadata: { configOptions, dataType, ...metadata } } = getProductBySku(reportRunSku);

                    const {
                        includeBrands,
                        includeCharacteristicDimensions,
                        includeAttributeGroup,
                        includeProductSubcategory,
                        includeOmniProducts,
                        includeCharacteristics
                    } = configOptions.find((options) => options.type === 'product') as ProductRunConfigOptions;

                    const isOmniReport = dataType === 'omni';

                    const selectedCategoriesWithUnsubscribedCatsRemoved = selectedCategories?.filter((selectedCategory) => categoryAppearsInGivenList(selectedCategory, isOmniReport ? subscriptionOmniCategories : subscriptionCategories)) ?? [];

                    const unsubscribedCatsWereRemoved = selectedCategoriesWithUnsubscribedCatsRemoved?.length !== selectedCategories?.length;
                    
                    const reportConfigFilteredForSubscribedCategories: ReportRunConfig = {
                        ...configuration,
                        categories: selectedCategoriesWithUnsubscribedCatsRemoved,
                        ...(unsubscribedCatsWereRemoved ? {
                            ...(includeBrands ? {brands: []} : {}),
                            ...(includeProductSubcategory ? {subcategories: []} : {}),
                            ...(includeCharacteristicDimensions ? {productDimensions: []} : {}),
                            ...(includeAttributeGroup ? {productDimensions: []} : {}),
                            ...(includeOmniProducts ? {omniFocusProducts: []} : {}),
                            ...(includeCharacteristics ? {characteristics: []} : []),
                            // ...(includeCharacteristics ? {
                            //     characteristics: characteristics?.length ? await removeCharsWithUnsubcribedCats(characteristics, subscriptionCustomCharacteristics, selectedCategoriesWithUnsubscribedCatsRemoved) : []
                            // } : {}),
                            markets: [],
                            subMarkets: []
                        } : {}),
                    };
                    
                    if (reportRunSku === sku) {
                        setDefaultRunConfig(reportConfigFilteredForSubscribedCategories);
                    } else {
                        setDefaultRunConfig(getReportRunConfig(sku, reportConfigFilteredForSubscribedCategories));
                    }
                } catch (err) {
                    console.error(err);
                }
            } else {
                setDefaultRunConfig(await getDefaultRunConfigBySku(sku));
            }

            if (additionalPayload){
                defaultRunConfig.focusBrands = additionalPayload?.brands
                defaultRunConfig.timePeriod = additionalPayload?.timePeriod
                defaultRunConfig.categories=additionalPayload?.category  || ['COFFEE']
                defaultRunConfig.markets =additionalPayload?.markets
                setDefaultRunConfig(defaultRunConfig);
            }
            if (runType !== 'adhoc') {
                const credits = await getMySubscriptionUsage();
                setBasicReportsUsage(credits.basicReports);
            }

            if (runType === 'adhoc' || runCreditId) {
                const runConfigOptionsForCreditId = await getRunConfigOptionsByCreditId(Number(runCreditId));
                setRunConfigOptions(runConfigOptionsForCreditId);
            } else {
                const reportRunConfigOptions = getRunConfigOptionsBySku(sku!)
                setRunConfigOptions(reportRunConfigOptions as RunConfigOptions[]);
            }

        } finally {
            // setLoading(false);
        }
    }

    async function validateOrExit(): Promise<boolean> {

        if (!['subscription', 'adhoc'].includes(runType)) {
            await alert({
                // @ts-ignore
                title: 'Invalid Report Run',
                content: 'Only subscription and adhoc runs are supported.',
            });
            navigate(-1);
            return false;
        }

        if (runType === 'subscription' && !subscribedSkus.includes(sku as string)) {

            await alert({
                // @ts-ignore
                title: 'Product Not Available',
                content: 'The product you selected is not available in your current subscription.',
            });
            navigate(-1);
            return false;
        }

        if (runType === 'adhoc' && !runCreditId) {
            await alert({
                // @ts-ignore
                title: 'Invalid Credit',
                content: 'You have not purchased this ad hoc report.',
            });
            navigate(-1);
            return false;
        }

        return true;
    }

    async function generateReport(runConfig: ReportRunConfig): Promise<void> {

        try {
            setGenerating(true)
            let reportId: string | undefined; // pizza
            if (runType === 'subscription' && sku) {
                reportId = await createSubscriptionReportRun(sku, runConfig);
            } else if (runType === 'adhoc' && runCreditId) {
                reportId = await createAdHicReportRun(runCreditId, runConfig);
            }

            if (reportId) {
                navigate(`/report/${reportId}`, {replace: true});
            }
        } catch (err) {
            showErrorModal(err);
            setGenerating(false);
        }
    }

    // @ts-ignore
    return <DashboardContent
        title={<>Design Your {report?.title} Report</>}
        className={`${baseClassName}__container`}
        loading={!runConfigOptions}
        numberTag={<NumberTagReportRuns reportType={reportType} runType={runType} reportRuns={basicReportsUsage.limit} />}
        extras={(
            <div className={`${baseClassName}__header-actions`}>
                <ByzzerButton type={'negative'} label={'Cancel'} onClick={() => navigate(-1)}/>
            </div>
        )}
    >
        {runConfigOptions && (
            <ReportRunConfigWizard sku={sku!}
                                   onComplete={generateReport}
                                   runType={runType}
                                   defaultValues={defaultRunConfig}
                                   busy={generating}
                                   runConfigOptions={runConfigOptions}
                                   />
        )}
    </DashboardContent>
}

function NumberTagReportRuns({ reportType, runType, reportRuns }) {
    if(runType === 'adhoc' || (reportRuns === Infinity || reportRuns === 0)) return <></>;

    return (
        <div className={`report-run-selector__tag`}>
            {'-1 run'}
            <ByzzerTipIcon
                tip={`This will deduct 1 ${reportType} run credit from your subscription`}
                tipLocation="right"
            />
        </div>
    );
}

type ExtraProps = {
    report: any;
}

function Extras({report}) {

    const navigate = useNavigate();

    return <div className={`${baseClassName}__report-thumb`}>
        <div className={`${baseClassName}__report-back`}>
            <ByzzerButton type={'outline'} label={'Cancel'} onClick={() => navigate(-1)}/>
        </div>
        {report.thumbnailUrl && <img src={report.thumbnailUrl} alt="Logo"/>}
    </div>
}

